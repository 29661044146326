@use 'sass:math';

// iconfont vars
$icons: (
	'arrow1-r': '\e900',
	'arrow1-l': '\e901',
	'arrow1-b': '\e902',
	'arrow1-t': '\e903',
	'arrow2-r': '\e904',
	'arrow2-l': '\e905',
	'arrow2-b': '\e906',
	'arrow2-t': '\e907',
	'blank': '\e908',
	'plus': '\e909',
	'minus': '\e90a',
	'close': '\e90b',
	'download': '\e90c',
	'serch': '\e90d',
	'access': '\e90e',
	'locked': '\e90f',
	'unlocked': '\e910',
	'global': '\e911',
	'question': '\e912',
	'exclamation': '\e913',
	'pdf': '\e914',
	'excel': '\e915',
	'powerpoint': '\e916',
	'word': '\e917',
	'zip': '\e918',
	'url': '\e919',
	'doc': '\e91a',
	'check': '\e91b',
	'circle': '\e91c',
	'circle_border': '\e91d',
	'play': '\e91e',
	'stop': '\e91f',
	'swipe': '\e920',
	'rss': '\e921',
	'alert': '\e922',
	'print': '\e923',
	'calendar': '\e924',
	'cart': '\e925',
	'humberger': '\e926',
	'bookmark': '\e927',
	'mail': '\e928',
	'first': '\e929',
	'last': '\e92a',
	'new': '\e92b',
	'to-top': '\e92c',
	'pinch-in': '\e92d',
	'pinch-out': '\e92e',
	'phone': '\e92f',
	'home': '\e934',
	'zoom': '\e935',
	'menu': '\e936',
	'triangle_r': '\e937',
	'triangle_b': '\e938'
);

// PCビュー
@mixin mq-pc {
	@media (min-width: 768px) {
		@content;
	}
}

// SPビュー
@mixin mq-sp {
	@media (max-width: 767px) {
		@content;
	}
}

// 基本コンテンツ幅設定
@mixin inner-content {
	width: 100%;
	// max-width: 1240px;
	max-width: 1140px;
	margin-left: auto;
	margin-right: auto;
	// padding-left: percentage( math.div(20, 375) );
	// padding-right: percentage( math.div(20, 375) );
	padding-left: 20px;
	padding-right: 20px;

	@include mq-pc {
		// width: 1240px,
		padding-left: 50px;
		padding-right: 50px;
	}
}

// clrfx
@mixin clrfx {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}


// ----------
// 便利系（例）
// ----------

// 直接アイコンフォントを設定する一式
@mixin iconfont-default {
	font-family: iconfont !important; /* stylelint-disable-line declaration-no-important,font-family-no-missing-generic-family-keyword */
	speak: none;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
}

// 上下のマージンを強制削除
@mixin margin-trim {
	> *:first-child {
		margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
	}

	> *:last-child {
		margin-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
	}
}

@function hex2rgb($color) {
	@return red($color), green($color), blue($color);
}
