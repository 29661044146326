@use 'sass:math';
@use '../../_mixin' as m;

@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap);


/* stylelint-disable no-descending-specificity */
/*----------
	override
----------*/
.l-fnav-main {
	$_this: &;

	@include m.mq-pc {
		&__inner {
			max-width: 1300px;
		}

		&__arrow {
			max-width: 1200px;
		}
	}
}

.l-fnav-sub {
	@include m.mq-pc {
		padding: 40px 0;

		&__inner {
			max-width: 1300px;
		}
	}

	@media (min-width: 1025px) {
		&__inner {
			max-width: 1300px;
		}
	}
}

/*----------
	top
----------*/
// section
// セクション枠 最大幅設定・中央配置
.un-section {
	width: 100%;
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 20px;
	padding-right: 20px;

	@include m.mq-pc {
		padding-left: 50px;
		padding-right: 50px;
	}
}


// mv
.un-mv {
	position: relative;
	margin-bottom: 40px;
	padding-top: 40px;
	padding-left: 20px;
	padding-right: 20px;

	@include m.mq-pc {
		margin-top: 0;
		margin-bottom: 80px;
		margin-top: 0;
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}
}

.un-mv-slider {
	position: relative;

	&__item {
		position: relative;
		overflow: hidden;

		&::before {
			content: '';
			display: block;
			padding-top: 250px;
		}

		img {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&.is-active,
		&.swiper-slide-duplicate-active,
		&.swiper-slide-prev {
			img {
				animation: my-slider-zoom-out 10s linear 0s 1 normal both;
			}
		}
	}

	&__controllers {
		position: absolute;
		bottom: 16px;
		right: 20px;
		z-index: 2;
	}

	&__pagination {
		display: flex;
	}

	&__bullet {
		display: block;
		width: 10px;
		height: 10px;
		margin: 0 5px;
		border-radius: 50%;
		background: transparent;
		cursor: pointer;
		border: 1px solid #fff;

		&.is-active {
			background-color: #fff;
		}
	}

	@include m.mq-pc {
		&__item {
			&::before {
				padding-top: percentage(math.div(480, 720));
			}
		}
	}
}

.un-mv-textmvset {
	&__image {
		margin-left: -20px;
		margin-right: -20px;
	}

	&__copy {
		margin-top: 30px;
		font-size: 1.3rem;
		line-height: 1.8;
	}

	&__infobox {
		margin-top: 24px;
	}

	@include m.mq-pc {
		&__inner {
			display: flex;
			align-items: center;
			margin: 0 auto;
		}

		&__texts {
			flex: 1;
			margin-left: math.div(82, 1440) * 100vw;
		}

		&__image {
			width: math.div(720, 1440) * 100vw;
			margin: 0;
		}

		&__copy {
			margin-top: 40px;
			margin-left: math.div(82, 1440) * 100vw;
			margin-right: math.div(82, 1440) * 100vw;
			font-size: clamp(10px, math.div(15, 1440) * 100vw, 17px);
			line-height: 2.3;
		}

		&__infobox {
			margin-top: 48px;
			margin-left: math.div(82, 1440) * 100vw;
			margin-right: math.div(82, 1440) * 100vw;
			// margin-left: 50px;
			// margin-right: 50px;
		}
	}
}

@keyframes my-slider-zoom-out {
	0% {
		transform: scale3d(1.1, 1.1, 1);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
}

/*----------
	heading / lead
----------*/

.un-heading-primary {
	margin-bottom: 24px;
	// opacity: 0;

	// @at-root .is-font-ready & {
	// 	opacity: 1;
	// 	transition: opacity .4s ease-in-out;
	// }

	&__title {
		font-size: 2.1rem;
		line-height: 1.6;
		letter-spacing: .1em;
		font-weight: bold;
		font-family: var(--font-yakuhanjp), var(--font-sans);
		margin-bottom: 12px;

		> span {
			display: inline-block;
			transform: translateY(42%);
			opacity: 0;
			transition: transform .55s cubic-bezier(.62, .05, .01, .99),  opacity .55s cubic-bezier(.62, .05, .01, .99);
		}

		&.is-inview {
			> span {
				transform: translateY(0);
				opacity: 1;

				@for $i from 1 through 100 {
					&:nth-child(#{$i}) {
						transition-delay: 30ms * $i;
					}
				}
			}
		}
	}

	&__text {
		font-size: 1.2rem;
		font-weight: 500;
		font-family: Montserrat, sans-serif;
		letter-spacing: normal;
		white-space: nowrap;

		> span {
			display: inline-block;
			transform: translateY(42%);
			opacity: 0;
			transition: transform .55s cubic-bezier(.62, .05, .01, .99),  opacity .55s cubic-bezier(.62, .05, .01, .99);

			&.multiply {
				font-weight: normal;
			}
		}

		&.is-inview {
			> span {
				transform: translateY(0);
				opacity: 1;

				@for $i from 1 through 100 {
					&:nth-child(#{$i}) {
						transition-delay: (10ms * $i) + 800ms;
					}
				}
			}
		}
	}

	@include m.mq-pc {
		margin-bottom: 0;

		&__title {
			font-size: clamp(16px, math.div(38, 1440) * 100vw, 48px);
			margin-bottom: math.div(12, 1440) * 100vw;
		}

		&__text {
			font-size: clamp(12px, math.div(18, 1440) * 100vw, 20px);
		}
	}
}

.un-lead {
	margin-bottom: 40px;

	&__text {
		font-size: 1.8rem;
		line-height: 1.6;
		font-weight: 500;
	}

	@include m.mq-pc {
		margin-bottom: 80px;

		&__text {
			font-size: 2.6rem;
		}
	}
}

.un-heading-secondary {
	margin-bottom: 32px;
	font-weight: bold;

	&__title {
		font-size: 2.4rem;
		font-weight: bold;
		line-height: 1.5em;
		display: inline-block;
		position: relative;
		padding-bottom: 14px;

		> span {

			&::after {
				content: '';
				position: absolute;
				display: inline-block;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 3px;
				background-color: var(--color-prim);
			}
		}
	}

	&__subtitle {
		font-size: 1.4rem;
		font-weight: 500;
		line-height: 1.5em;
		margin-bottom: 8px;
	}

	@include m.mq-pc {
		margin-bottom: 40px;

		&__title {
			font-size: 3.2rem;
			padding-bottom: 11px;
		}

		&__subtitle {
			font-size: 2rem;
			margin-bottom: 16px;
		}
	}
}

/*----------
	service
----------*/
.un-service-card-container {
	position: relative;
	@include m.mq-pc {
		padding-right: percentage(math.div(500, 1200));
	}
}

.un-service-card {

	$_this: '.un-service-card';

	padding: 20px 0 34px;
	border-top: 1px solid var(--color-border);
	border-bottom: 1px solid var(--color-border);

	& + & {
		border-top: none;
	}

	&__inner {
		display: flex;
		justify-content: space-between;
		height: 100%;
		position: relative;
		cursor: pointer;
	}

	&__image {

		// 画像比率固定用の記述
		width: percentage(math.div(96, 335));
		min-height: 138px;
		padding-bottom: percentage(math.div(138, 335));
		position: relative;
		overflow: hidden;
		z-index: 1;

		> img {
			width: 100%;
			height: auto;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
			transition: .2s;
			height: 100%;
			width: 100%;
		}
	}

	&__header {
		display: block;
		width: percentage(math.div(215, 335));
	}

	&__title {
		display: block;
		position: relative;
		font-size: 1.6rem;
		color: var(--color-txt);
		font-weight: 500;
		padding-top: 12px;
	}

	&__text {
		margin-top: 14px;
		color: var(--color-txt);

		// > span {
		// 	color: var(--color-txt);
		// }
	}

	@include m.mq-pc {
		position: static;
		margin-right: 40px;
		z-index: 1;
		padding: 0;

		&:first-of-type {
			#{$_this}__image {
				opacity: 1;
			}
		}

		&.is-active {
			z-index: 2;
		}

		&__inner {
			position: static;
			padding: 36px 40px;
			width: 100%;
			background-color: transparent;

			&.is-active {
				background-color: #EBECF2;

				#{$_this}__title {
					font-size: 2.2rem;
					padding-left: 26px;

					&::before {
						opacity: 1;
					}
				}

				#{$_this}__image {
					opacity: 1;

					> img {
						transform: scale(1.02);
					}
				}

				#{$_this}__text {
					color: var(--color-txt);
				}

			}
		}

		&__image {
			position: absolute;
			right: 0;
			top: 0;
			width: percentage(math.div(500, 1200));
			height: 100%;
			padding: 0;
			pointer-events: none;
			opacity: 0;
			transition: .3s;
		}

		&__header {
			width: 100%;
		}

		&__title {
			font-size: 2rem;
			transition: .3s;
			height: 3.6rem;
			line-height: 3.6rem;
			padding: 0;
			transition: .3s;

			&::before {
				content: '';
				display: inline-block;
				opacity: 0;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				overflow: hidden;
				background: #2D4BBA;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				transition: .3s;
				transition-delay: .1s;
			}
		}

		&__text {
			max-width: 420px;
			line-height: 1.6;
			margin-top: 6px;
			margin-right: auto;
			font-size: 1.4rem;
			color: #666;
			transition: .3s;
		}
	}
}

/*----------
	works
----------*/
.un-pickup {
	margin-bottom: 72px;
}

/*----------
	column
----------*/
.un-largecard-container {
	margin: 0 auto 40px;

	&__item + &__item {
		margin-top: 40px;
	}

	@include m.mq-pc {
		width: 50%;
		display: grid;

		@supports (display: grid) {
			gap: 80px 40px;
			width: auto;
			grid-template-columns: repeat(3, 1fr);

			&__item {
				&:nth-child(2n) {
					margin-top: 40px;
				}

				&:nth-child(3n) {
					margin-top: 80px;
				}
			}
		}
	}
}


.un-largecard {

	$_this: '.un-largecard';

	&__inner {
		height: 100%;
		position: relative;
		cursor: pointer;
	}

	&__image {

		// 画像比率固定用の記述
		// min-height: 335px;
		position: relative;
		overflow: hidden;
		z-index: 1;
		margin: 0 auto 24px;
		width: percentage(math.div(252, 335));

		&::before {
			content: '';
			display: block;
			padding-bottom: percentage(math.div(307, 230));
		}

		> img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
			transition: .2s;
		}
	}

	&__header {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		overflow: hidden;
	}

	&__title {
		display: block;
		width: 100%;
		padding: 0 0 16px;
		position: relative;
		font-size: 1.8rem;
		color: var(--color-txt);
		font-weight: 500;
	}

	&__tag {
		width: 100%;
	}

	@include m.mq-pc {
		&__inner {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			flex-direction: column;
			// height: 100%;
			position: relative;
			cursor: pointer;

			&:hover {
				#{$_this}__title {
					// text-decoration: underline;

					> span {
						background-position: 0 100%;
						background-size: 100% 1px;
					}
				}

				#{$_this}__tag {
					.c-tag {
						> span {
							background-color: var(--color-txt);
							color: #fff;
						}
					}
				}

				#{$_this}__image {
					> img {
						transform: scale(1.05);
					}
				}

			}
		}

		&__image {
			// height: 0;
			// width: 100%;
			// padding-bottom: percentage(math.div(307, 230));
			// position: absolute;
			width: 100%;
			margin-bottom: 32px;

			// > img {
			// 	height: 100%;
			// }
		}

		&__header {
			transition: color .2s;
			font-size: 1.6rem;
			width: 100%;
			padding-top: 0;
			// height: 100%;
			// min-height: 60px;
			// padding-top: percentage(math.div(307, 230));
		}

		&__title {
			padding: 0;
			// padding: 24px 0 0;
			// font-size: 2.4rem;

			> span {
				background: linear-gradient(var(--color-txt), var(--color-txt)) 100% 100% / 0 1px no-repeat;
				transition: background-size .4s, color .3s;
			}
		}

		&__tag {
			padding: 16px 0 0;
		}
	}
}

@keyframes rotation {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}
/* stylelint-enable no-descending-specificity */
